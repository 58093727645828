import { lazy, useEffect } from "react";
import { Routes } from "react-router-dom";

import { useProfile } from "./components/useProfile";
import { Route } from "./components/Route";
import { LoadingFullScreen } from "./components/LoadingFullScreen";

import Layout from "./Layout.agent";
import { AgentLayout, AgentView } from "./components/AgentLayout/AgentLayout";

import Messages from "./views/AgentMessages";
import Tasks from "./views/AgentTasks/AgentTasks";
import { FilterProvider } from "./components/useFilter";
const Assignments = lazy(() => import("./views/AgentAllAssignments"));
const Assignment = lazy(
  () => import("./views/AgentAssignment/AgentAssignment")
);
const Appointments = lazy(() => import("./views/AgentAppointments"));
const Documents = lazy(() => import("./views/AgentDocuments"));
const Document = lazy(() => import("./views/Document"));
const Profile = lazy(() => import("./views/Profile"));

function App() {
  const { data: user, isLoading, isError, error } = useProfile("agent");
  const authenticated = !isError && Boolean(Object.keys(user || {}).length);

  useEffect(() => {
    if (error && error.redirect_url) {
      window.location.href = error.redirect_url;
    }
  }, [error]);

  if (error && !error.redirect_url) {
    return <p>Du er ikke logget inn..</p>;
  }

  if (!authenticated || isLoading || isError) {
    return <LoadingFullScreen />;
  }

  return (
    <FilterProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Assignments />} />
          <Route
            path="oppdrag/*"
            element={
              <AgentLayout
                title="Oppdrag"
                element={<AgentView children={<Assignment />} />}
              />
            }
          />

          <Route
            path="oppgaver/*"
            element={
              <AgentLayout
                path=":sale_id/*"
                title="Oppgaver"
                mapKeys={["*", "sale_id"]}
                element={<Tasks />}
              />
            }
          />

          <Route
            path="avtaler/*"
            element={
              <AgentLayout
                title="Avtaler"
                path=":sale_id/*"
                mapKeys={["*", "sale_id"]}
                element={<AgentView width="full" children={<Appointments />} />}
              />
            }
          />
          <Route
            path="meldinger/*"
            element={
              <AgentLayout
                title="Meldinger"
                path=":sale_id/*"
                showMessageCount
                mapKeys={["*", "sale_id"]}
                element={<Messages />}
              />
            }
          />

          <Route
            path="dokumenter/*"
            element={
              <AgentLayout
                path=":sale_id/*"
                title="Dokumenter"
                mapKeys={["*", "sale_id"]}
                element={<AgentView width="full" children={<Documents />} />}
              />
            }
          />

          <Route
            path="dokumenter/:sale_id/dokument/:documentId"
            element={<Document />}
          />

          <Route path="profil" element={<Profile />} />
        </Route>
      </Routes>
    </FilterProvider>
  );
}

export default App;
