// extracted by mini-css-extract-plugin
var _1 = "QHNwZu9Km4oyzMgbFKdL";
var _2 = "P2cVJmY02Ko3iavmbl2M";
var _3 = "awUGMAkizqeKraKiu2hV";
var _4 = "aXiVK4yXw1KqCRYfrfZr";
var _5 = "XEqs90ewNHsUbUEFl9T5";
var _6 = "K_Jmn7MNDHfyUF3u9Y1n";
var _7 = "svWxU3QdS5UELy_dsmKA";
var _8 = "mugDCxfOnqXIWv6KLRl6";
export { _1 as "responsive", _2 as "spacing_l", _3 as "spacing_m", _4 as "spacing_s", _5 as "spacing_xl", _6 as "spacing_xs", _7 as "spacing_xxl", _8 as "spacing_xxs" }
