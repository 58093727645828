/**
 * A type-safe polymorphic component that can render as different HTML elements.
 *
 * @template Default - The default element type this component renders as
 * @template Props - Additional props specific to this component
 * @template OnlyAs - Constraint for which element types are allowed
 *
 * @param props - Component props including:
 * @param props.as - Override the rendered element type
 * @param props.open - Optional boolean prop
 * @param ref - Forwarded ref that will be attached to the rendered element
 *
 * @example
 * ```tsx
 * // Renders as a div by default
 * <Box>Content</Box>
 *
 * // Can render as other elements
 * <Box as="section">Content</Box>
 *
 * // Forwards refs
 * const ref = useRef<HTMLDivElement>(null);
 * <Box ref={ref}>Content</Box>
 * ```
 *
 * @returns ReactElement - The rendered element with forwarded props and ref
 */

import {
  forwardRef,
  type ComponentPropsWithRef,
  type ElementType,
  type ForwardRefExoticComponent,
  type ForwardRefRenderFunction,
  type ReactElement,
} from "react";

type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

type Merge<A, B> = Omit<A, keyof B> & B;

type DistributiveMerge<A, B> = DistributiveOmit<A, keyof B> & B;

export type AsProps<
  Component extends ElementType,
  PermanentProps extends object,
  ComponentProps extends object,
> = DistributiveMerge<ComponentProps, PermanentProps & { as?: Component }>;

export type PolymorphicWithRef<
  Default extends OnlyAs,
  Props extends object = {},
  OnlyAs extends ElementType = ElementType,
> = <T extends OnlyAs = Default>(
  props: AsProps<T, Props, ComponentPropsWithRef<T>>
) => ReactElement | null;

export type PolyForwardComponent<
  Default extends OnlyAs,
  Props extends object = {},
  OnlyAs extends ElementType = ElementType,
> = Merge<
  ForwardRefExoticComponent<
    Merge<ComponentPropsWithRef<Default>, Props & { as?: Default }>
  >,
  PolymorphicWithRef<Default, Props, OnlyAs>
>;

export type PolyRefFunction = <
  Default extends OnlyAs,
  Props extends object = {},
  OnlyAs extends ElementType = ElementType,
>(
  Component: ForwardRefRenderFunction<any, Props & { as?: OnlyAs }>
) => PolyForwardComponent<Default, Props, OnlyAs>;

const polymorphicForwardRef = forwardRef as PolyRefFunction;

interface BoxProps {
  open?: boolean;
}

const Box = polymorphicForwardRef<"div", BoxProps>(
  ({ as: Element = "div", ...props }, ref) => <Element ref={ref} {...props} />
);

Box.displayName = "Box";
export { Box, polymorphicForwardRef };
