import {
  Box,
  type PolyForwardComponent,
  polymorphicForwardRef,
} from "../../Box";

import * as styles from "./styles.module.css";

export interface IUITextProps {
  size?: "13" | "14" | "16" | "18" | "22" | "28" | "32";
  bold?: boolean;
  underline?: boolean;
  color?: "default" | "muted" | "dim";
  smallcaps?: boolean;
  className?: string;
}

const defaultElement = "p";

const UIText: PolyForwardComponent<typeof defaultElement, IUITextProps> =
  polymorphicForwardRef<typeof defaultElement, IUITextProps>(
    (
      {
        size = "16",
        bold,
        color,
        smallcaps,
        className,
        underline,
        as: Component = defaultElement,
        ...props
      },
      ref
    ) => {
      return (
        <Box
          as={Component}
          className={`${className || ""} ${styles[`size_${size}`]} ${
            bold ? styles.bold : ""
          } ${color === "muted" ? styles.muted : ""} ${
            color === "dim" ? styles.dim : ""
          } ${smallcaps ? styles.smallcaps : ""} ${
            underline ? styles.underline : ""
          }`.trim()}
          ref={ref}
          {...props}
        />
      );
    }
  );

UIText.displayName = "UIText";
export { UIText };
