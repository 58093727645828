import { address } from "../../utils/address";
import { Link } from "react-router-dom";

import * as styles from "./styles.module.css";
import { Badge } from "../Badge";

import { ReactComponent as MailIcon } from "symbols/mail.svg";

type SaleCardProps = {
  property: IAdminProperty;
};

export default function SaleCard({ property }: SaleCardProps) {
  return (
    <Link to={String(property.id)}>
      <div className={styles.saleCard}>
        <p className={styles.title}>{address.toDisplay(property.address)}</p>
        {property.status.unread_messages > 0 && (
          <div>
            <MailIcon />
            <Badge className={styles.unreadMessages} theme="dot">
              {property.status.unread_messages}
            </Badge>
          </div>
        )}
      </div>
    </Link>
  );
}
