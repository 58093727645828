import { type ChangeEvent } from "react";
import { Dropdown, Card, CardSection, InputCheckbox } from "therese";
import { VStack } from "../VStack";
import { agentPhaseFilter } from "../../utils/phaseText";
import { agentRolesFilter } from "../../utils/roleText";
import { useFilter } from "../useFilter";

export const AgentPhaseFilter = () => {
  const { phases, roles, updateFilter } = useFilter();

  const handleFilterChange = (
    event: ChangeEvent<HTMLInputElement>,
    filterType: "phases" | "roles"
  ) => {
    event.stopPropagation();
    updateFilter(event.target.value, filterType);
  };

  return (
    <Dropdown title="Filtrer">
      <Card as="form">
        <CardSection collapsible initialOpen title="Fase">
          <VStack spacing="xxs">
            {agentPhaseFilter.items.map((item) => (
              <InputCheckbox
                key={item.label}
                checked={phases.includes(item.value)}
                {...item}
                onChange={(event) => handleFilterChange(event, "phases")}
              />
            ))}
          </VStack>
        </CardSection>

        <CardSection collapsible title="Rolle">
          <VStack spacing="xxs">
            {agentRolesFilter.items.map((item) => (
              <InputCheckbox
                key={item.label}
                checked={roles.includes(item.value)}
                {...item}
                onChange={(event) => handleFilterChange(event, "roles")}
              />
            ))}
          </VStack>
        </CardSection>
      </Card>
    </Dropdown>
  );
};
