// extracted by mini-css-extract-plugin
var _1 = "fjCMd7Irhpn3hYdm1W8Q";
var _2 = "UVwqvNV8CEQ9T3OgHBHV";
var _3 = "NvyRNLesd6nj_L4Hl7PQ";
var _4 = "gXuPUezwqLbwo5ROp57u";
var _5 = "iWbGPz5OtwLpJhALVR5c";
var _6 = "YCnP0rMbTfzycWsG8Ec6";
var _7 = "kZ2v9Lrs__7RcwZrpQzi";
var _8 = "kaDvL7xq915o4XIg_Fho";
var _9 = "FS35sbksnnFEDHXErb5y";
var _a = "UTmpMTICRt9QpDw11a24";
var _b = "vS2CW9IPCXtBU52YkmIQ";
var _c = "nYFkghf0KGHHMo0dQfGm";
export { _1 as "bold", _2 as "dim", _3 as "muted", _4 as "size_13", _5 as "size_14", _6 as "size_16", _7 as "size_18", _8 as "size_22", _9 as "size_28", _a as "size_32", _b as "smallcaps", _c as "underline" }
