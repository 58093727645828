import { type ChangeEvent, type ReactElement, type ReactNode } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";

import { agentPhaseFilter } from "../../utils/phaseText";
import { useFilter } from "../useFilter";
import { address } from "../../utils/address";
import { useProperties } from "../useAgentProperties";
import { usePortalQuery } from "../usePortalQuery";
import { AgentPhaseFilter } from "../AgentPhaseFilter/AgentPhaseFilter";

import {
  Loading,
  EmptyState,
  Dropdown,
  Card,
  InputText,
  TooltipIcon,
} from "therese";
import { SplitView, type ISplitViewProps, View } from "../SplitView";
import { Container, type IContainerProps } from "../Layout/Layout";
import SaleCard from "../SaleCard";
import { UIText } from "../UIText";

import { ReactComponent as ArrowIcon } from "symbols/arrow_up_right.svg";
import { ReactComponent as InfoIcon } from "symbols/circle_info.svg";
import { ReactComponent as SearchIcon } from "symbols/search.svg";

import * as styles from "./styles.module.css";
import * as colors from "../../styles/shared.module.css";

enum ColorCoding {
  mintGreenLight = 0,
  mintGreen,
  summerGreen,
  seaGreen,
  emeraldGreen,
  oceanGreen,
}

interface IAgentLayoutProps extends Pick<ISplitViewProps, "mapKeys"> {
  title: string;
  element: ReactElement;
  path?: string;
  children?: ReactNode;
  showMessageCount?: boolean;
}

function AgentLayout(props: IAgentLayoutProps) {
  const { debouncedFilters, searchString, updateSearch } = useFilter();
  const { data, isLoading, isFetching } = useProperties(
    debouncedFilters.phases,
    debouncedFilters.roles,
    debouncedFilters.searchString
  );

  const loading = isLoading || isFetching;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SplitView
            title={props.title}
            mapKeys={props.mapKeys}
            headerAside={<AgentPhaseFilter />}
            filterInput={
              <InputText
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  updateSearch(e.target.value)
                }
                value={searchString}
                name="properties-filter"
                id="properties-filter"
                placeholder="Søk i listen"
                icon={<SearchIcon />}
              />
            }
          >
            {loading && (
              <>
                <br />
                <Loading skipDelay />
              </>
            )}
            {!loading && (
              <div className={styles.propertyResultsContainer}>
                {agentPhaseFilter.items.map((item, index) => {
                  const filteredProperties = data?.properties.filter(
                    (property) => property.phase.toString() === item.value
                  );
                  const phaseClass =
                    ColorCoding[item.value as keyof typeof ColorCoding];

                  return filteredProperties && filteredProperties.length > 0 ? (
                    <nav
                      className={`${styles.filterGroup} ${colors[phaseClass]}`}
                      key={item.value}
                      aria-labelledby={`filter-group-${item.label.replace(
                        /\s/g,
                        ""
                      )}`}
                    >
                      <div className={styles.filterGroupHeader}>
                        <UIText
                          as="h3"
                          bold
                          id={`filter-group-${item.label.replace(/\s/g, "")}`}
                          className={colors[phaseClass]}
                        >
                          {item.label} ({filteredProperties.length})
                        </UIText>
                        <TooltipIcon
                          icon={<InfoIcon />}
                          text={`Fase i Next: ${item.nextlabel}`}
                        />
                      </div>
                      <ul>
                        {filteredProperties.map((property) => (
                          <li key={property.id} className={colors[phaseClass]}>
                            <SaleCard property={property} />
                          </li>
                        ))}
                      </ul>
                    </nav>
                  ) : null;
                })}
              </div>
            )}
          </SplitView>
        }
      >
        {props.children || (
          <>
            <Route path={props.path || ":sale_id"} element={props.element} />
            <Route
              path=""
              element={
                <Container>
                  <EmptyState>Velg oppdrag fra menyen</EmptyState>
                </Container>
              }
            />
          </>
        )}
      </Route>
    </Routes>
  );
}

interface IAgentViewProps extends Pick<IContainerProps, "width"> {
  children: ReactNode;
}

function AgentView(props: IAgentViewProps) {
  const { sale_id } = useParams();
  const navigate = useNavigate();
  const { data: property, isLoading } = usePortalQuery<IAdminProperty>(
    "agents/property",
    { onError: () => navigate("../") } // TODO: Show error if sale doesn't exist ? (favro DNB-4699)
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <View
      header={
        property && sale_id ? (
          <div className={styles.header}>
            <h3 className={styles.bold}>
              {address.toDisplay(property.address)}
            </h3>
            <div>
              <Dropdown title="Vis portal">
                <Card>
                  <a
                    href={getPortalUrl(sale_id) + "?mode=sell"}
                    className={styles.link}
                  >
                    Som selger <ArrowIcon />
                  </a>
                  <a
                    href={getPortalUrl(sale_id) + "?mode=buy"}
                    className={styles.link}
                  >
                    Som kjøper <ArrowIcon />
                  </a>
                  {property.spouse ? (
                    <a
                      href={getPortalUrl(sale_id) + "?mode=spouse"}
                      className={styles.link}
                    >
                      Som ektefelle <ArrowIcon />
                    </a>
                  ) : null}
                </Card>
              </Dropdown>
            </div>
          </div>
        ) : null
      }
    >
      {property && (
        <div className={styles.root}>
          <Container padding="s" width={props.width}>
            {props.children}
          </Container>
        </div>
      )}
    </View>
  );
}

function getPortalUrl(saleId: string): string {
  const origin = window.location.origin;
  const baseUrl = origin.replace("megler.", "").replace("1", "0");
  return `${baseUrl}/${saleId}`;
}

export { AgentView, AgentLayout };
