import {
  type ReactNode,
  type ReactElement,
  Children,
  isValidElement,
  cloneElement,
} from "react";
// import cx from "classnames";
import { useId } from "@reach/auto-id";

import * as styles from "./styles.module.css";

export interface IVStackProps {
  children: ReactNode;
  spacing?: "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl";
  responsive?: boolean;
  shouldWrapChildren?: boolean;
  includeLastChild?: boolean;
}

const DISPLAY_NAME = "VStack";
function VStack({ spacing = "xxs", ...props }: IVStackProps) {
  const id = useId();
  const validChildren = Children.toArray(props.children).filter(isValidElement);

  return (
    <>
      {validChildren.map((child: ReactElement, index) => {
        const type = child.type as { displayName?: string };
        const isVStackComponent = type.displayName === DISPLAY_NAME;
        const shouldWrapChildren =
          props.shouldWrapChildren || isVStackComponent;
        const lastChild = validChildren.length === index + 1;
        const addClassname = props.includeLastChild || !lastChild;
        let className =
          (child as ReactElement<{ className?: string }>).props.className || "";

        if (addClassname) {
          const spacingClass = styles[`spacing_${spacing}`];
          const responsiveClass = props.responsive ? styles.responsive : "";
          className = [className, spacingClass, responsiveClass]
            .filter(Boolean)
            .join(" ")
            .trim();
        }

        if (shouldWrapChildren && addClassname) {
          return (
            <div
              key={`${DISPLAY_NAME}:${id}::${index}`}
              className={className}
              children={child}
            />
          );
        }

        return cloneElement(child, { className } as { className: string });
      })}
    </>
  );
}

VStack.displayName = DISPLAY_NAME;

export { VStack };
