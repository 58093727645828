import { createContext, ReactNode, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, Outlet } from "react-router-dom";
import cx from "classnames";

import { UIText, useMediaQuery } from "therese";

import { Suspense } from "../Suspense";

import { Header } from "./Header";

import * as styles from "./styles.module.css";

// mapKeys is used to determine if the current route is a split view route
// if so, the navigation will be hidden, used for nested split views
export interface ISplitViewProps {
  title: string;
  children: ReactNode;
  mapKeys?: string[];
  nested?: boolean;
  headerAside?: ReactNode;
  filterInput?: ReactNode;
}

const SplitViewContext = createContext({ isSplitView: true });

function SplitView(props: ISplitViewProps) {
  const params = useParams();
  const findKeys = Object.keys(params).filter((k) =>
    (props.mapKeys || []).includes(k)
  );
  const matchMappedKeys = props?.mapKeys?.toString() === findKeys.toString();
  const rootPath = params["*"] === "";
  const largeScreen = useMediaQuery(styles.mediumScreen);
  const isSplitView = useMediaQuery(
    styles[props.nested ? "largeScreen" : "mediumScreen"]
  );
  const showContent = !!matchMappedKeys || !(rootPath && !isSplitView);
  const showNavigation = isSplitView || !showContent;
  const showBack =
    (props.nested || false) && !isSplitView && rootPath && !largeScreen;

  return (
    <>
      <Helmet title={props.title}>
        <body className="view-messages" />
      </Helmet>

      <SplitViewContext.Provider value={{ isSplitView }}>
        <div className={cx(styles.root, { [styles.nested]: props.nested })}>
          {showNavigation && (
            <section className={styles.navigation}>
              <Header spacing back={showBack}>
                {props.headerAside ? (
                  <nav
                    className={styles.headerAside}
                    aria-label="Filter & Search"
                  >
                    <div className={styles.header}>
                      <UIText as="h2" size="16" bold>
                        {props.title}
                      </UIText>
                      {props.headerAside}
                    </div>
                    <div className={styles.filterInputContainer}>
                      {props.filterInput}
                    </div>
                  </nav>
                ) : (
                  <UIText as="h2" size="16" bold>
                    {props.title}
                  </UIText>
                )}
              </Header>

              {props.children}
            </section>
          )}
          {showContent && (
            <div className={styles.content}>
              <Outlet />
            </div>
          )}
        </div>
      </SplitViewContext.Provider>
    </>
  );
}

interface IView {
  children: ReactNode;
  header: ReactNode;
  alwaysShowBack?: boolean;
  doubleBack?: boolean;
}

function View(props: IView) {
  const { isSplitView } = useContext(SplitViewContext);

  return (
    <>
      <Header
        back={props.alwaysShowBack || !isSplitView}
        doubleBack={props.doubleBack}
      >
        {props.header}
      </Header>
      <Suspense>{props.children}</Suspense>
    </>
  );
}

export { SplitView, View };
