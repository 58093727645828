import {
  type ReactNode,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
import useDebouncedValue from "./useDebouncedValue";

interface IFilterContextType {
  phases: string[];
  roles: string[];
  searchString: string;
  updateFilter: (value: string, filterType: "phases" | "roles") => void;
  updateSearch: (value: string) => void;
  debouncedFilters: { phases: string[]; roles: string[]; searchString: string };
}

const FilterContext = createContext<IFilterContextType | undefined>(undefined);

export function FilterProvider({ children }: { children: ReactNode }) {
  const [phases, setPhases] = useState<string[]>(["2", "1", "0"]);
  const [roles, setRoles] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>("");

  const updateFilter = useCallback(
    (value: string, filterType: "phases" | "roles") => {
      if (filterType === "phases") {
        setPhases((current) =>
          current.includes(value)
            ? current.filter((v) => v !== value)
            : [...current, value]
        );
      } else {
        const isSpecialRole = value === "4";
        setRoles((current) => {
          if (isSpecialRole) {
            return current.includes("4") ? [] : ["4"];
          }
          const withoutSpecial = current.filter((v) => v !== "4");
          return current.includes(value)
            ? withoutSpecial.filter((v) => v !== value)
            : [...withoutSpecial, value];
        });
      }
    },
    []
  );

  const updateSearch = useCallback((value: string) => {
    setSearchString(value);
  }, []);

  const { value: debouncedFilters } = useDebouncedValue(
    { phases, roles, searchString },
    500
  );

  return (
    <FilterContext.Provider
      value={{
        phases,
        roles,
        searchString,
        updateFilter,
        updateSearch,
        debouncedFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context) throw new Error("useFilter must be used within FilterProvider");
  return context;
};
