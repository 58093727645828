import { type ReactNode } from "react";

import { UIText } from "../UIText";

import * as styles from "./styles.module.css";

interface IBadgeProps {
  className?: string;
  theme?:
    | "outline"
    | "default-theme"
    | "circle"
    | "dot"
    | "muted"
    | "warning"
    | "success-light"
    | "success"
    | "error";
  children: ReactNode;
}

const defaultElement = "span";

const Badge = ({ className, theme = "outline", ...props }: IBadgeProps) => {
  return (
    <UIText
      as={defaultElement}
      className={[styles[theme], className].filter(Boolean).join(" ")}
      size="13"
      bold
      {...props}
    />
  );
};

export { Badge };
