import { useQueryClient } from "react-query";
import { usePortalQuery } from "./usePortalQuery";

export interface IPagination extends IAdminPropertiesPagination {
  limit: number;
}

interface IUsePropertiesResults {
  properties: IAdminProperty[];
  pagination: IAdminPropertiesPagination;
}

function useProperties(phases?: string[], roles?: string[], search?: string) {
  const client = useQueryClient();

  const params = {
    phases: phases || [],
    roles: roles || [],
    search: search || "",
  };

  return usePortalQuery<IAdminProperties, unknown, IUsePropertiesResults>(
    ["agents/properties", { params }],
    {
      select: (data) => {
        return { ...data, pagination: { ...data.pagination } };
      },
      onSuccess: ({ properties }) => {
        properties.forEach((p) => {
          client.setQueryData(
            ["agents/property", { params: { sale_id: p.id } }],
            (old) => old || p
          );
        });
      },
    }
  );
}

export { useProperties };
